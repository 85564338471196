import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import Menu from "../../components/Menu/Menu";
import Header from "../../components/Header/Header";
import TxtBio from "../../components/TxtBio/TxtBio";
import "./Bio.scss";

const header = {
  abbr: "BIO",
  surtitle: "INFO",
  title: "Germán Consetti",
  subtitle: null,
  cita: null,
  author: null,
  author2: null,
};

const Bio = () => {
  const { t } = useTranslation("bio");
  const bio = t("bio", { returnObjects: true });

  return (
    <Suspense fallback="cargando..." >
      <div className="Bio">
        <Menu />
        <Header
          abbr={header.abbr}
          surtitle={header.surtitle}
          title={header.title}
          subtitle={header.subtitle}
          cite={header.cita}
          author={header.author}
          author2={header.author2}
        ></Header>
        <TxtBio body={bio} />
      </div>
    </Suspense>
  );
};

export default Bio;
