import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import Menu from "../../../components/Menu/Menu";
import Header from "../../../components/Header/Header";
import TxtGrid from "../../../components/TxtGrid/TxtGrid";
import "./Proyecto3.scss";

const Proyecto3 = () => {
  const { t } = useTranslation(["projects", "headers"]);
  const gridItems = t("projects.unaMetaforaEsUnaMatriz.grid", { ns: "projects", returnObjects: true });
  const body = t("projects.unaMetaforaEsUnaMatriz.body", { ns: "projects", returnObjects: true });

  const headerData = t("headers.proyecto3", {
    ns: "headers",
    returnObjects: true,
  });

  const header = {
    abbr: "PR3",
    surtitle: headerData.surtitle,
    title: headerData.title,
    subtitle: "Un proyecto de Germán Consetti y comisariado de Jordi Garrido",
    cita: "El trabajo de la intuición consistiría entonces en lo siguiente: separar los dos componentes inconscientes de la imagen vista e incorporada a la memoria, demostrar su identidad irracional y transportar esos dos componentes, como representación o percepción engañosa, a la superficie de la conciencia",
    author: "HANS BELLMER, ANATOMÍA DE LA IMAGEN",
    author2: "JORDI GARRIDO",
  };

  return (
    <Suspense>
      <div className="Proyecto3">
        <Menu />
        <Header
          abbr={header.abbr}
          surtitle={header.surtitle}
          title={header.title}
          subtitle={header.subtitle}
          cite={header.cita}
          author={header.author}
          author2={header.author2}
        ></Header>
        <TxtGrid list={gridItems} body={body} />
      </div>
    </Suspense>
  );
};

export default Proyecto3;
