import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../components/Grid/Grid";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import styles from "./Esculturas.module.scss";

const Esculturas = () => {
  const { t } = useTranslation(["artworks", "headers"]);
  const sculptures = t("artworks.sculptures", {
    ns: "artworks",
    returnObjects: true,
  });
  const headerData = t("headers.sculptures", {
    ns: "headers",
    returnObjects: true,
  });

  return (
    <div className={styles.Escultura}>
      <Menu />
      <Header
        title={headerData.title}
        surtitle={headerData.surtitle}
        subtitle={headerData.subtitle}
        abbr={headerData.abbr}
      ></Header>
      <Grid list={sculptures} />
    </div>
  );
};

export default Esculturas;
