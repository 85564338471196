import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Breakpoint } from "react-socks";
import HeaderMobile from "./HeaderMobile";
import AccordionMenu from "../AccordionMenu/AccordionMenu";
import MenuMobile from "./MenuMobile/MenuMobile";
import Lang from "../Lang/Lang";

import "./Menu.scss";

const Menu = () => {
  const [menuState, setMenuState] = useState(false);
  return (
    <div className="Menu">
      <Breakpoint screenSm up>
        <div className="MenuDesktop">
          <div className="MenuDesktop__top">
            <Link to="/" className="MenuDesktop__titulo">
              <p className="h2">germán</p>
              <p className="h2">consetti</p>
            </Link>
            <AccordionMenu />
          </div>
          <Lang />
        </div>
      </Breakpoint>
      <Breakpoint screenSm down>
        <HeaderMobile setState={setMenuState} />
        {menuState ? <MenuMobile setState={setMenuState} /> : null}
      </Breakpoint>
    </div>
  );
};

export default Menu;
