import React from "react";

import Slider from "../../components/SliderFicha/Slider";
import Menu from "../../components/Menu/Menu";
import "./Ficha.scss";

const Ficha = () => {
  return (
    <div className="Ficha">
      <Menu></Menu>
      <Slider className="Sliders" />
    </div>
  ) ;
};

export default Ficha;
