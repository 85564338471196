import React from "react";
import { Link } from "react-router-dom";

import "./ImgTxt.scss";
import expo from "./img-expo-pro2.jpg";
import pdf from "./Catalogo.pdf"

const ImgTxt = (props) => {
  return (
    <div className="ImgTxt">
      <div className="ImgTxt__left">
        <img src={expo} alt="" />
      </div>
      <div className="ImgTxt__right">
        <p>{props.data.intro}</p>
        <Link className="btn_pdf" to={pdf} target="_blank" download>{props.data.button}</Link>
      </div>
    </div>
  );
};

export default ImgTxt;
