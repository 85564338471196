import React from "react";
import Card from "./Card/Card";

import "./Grid.scss";

const Grid = (props) => {
  const renderList = Object.keys(props.list).map((key) => {
    return <Card data={props.list[key]} key={props.list[key].id} />;
  });

  return <div className="Grid">{renderList}</div>;
};

export default Grid;
