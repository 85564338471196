import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";
import "./Carrusel.scss";

const Carrusel = () => {
  const { t } = useTranslation();
  const data = t("artworks.sculptures", { returnObjects: true });

  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    slider1.current.sync(slider2.current.splide);
  }, [slider1, slider2]);

  const renderList1 = Object.keys(data).map((key) => {
    return (
      <SplideSlide className="Slide">
        <div className="Slide__img">
          <img src={data[key].imageLg} alt="img" />
        </div>
      </SplideSlide>
    );
  });
  const renderList2 = Object.keys(data).map((key) => {
    return (
      <SplideSlide className="Slide">
        <div className="Slide__footnote">
          <p className="Slide__title">{data[key].title}</p>
          <p className="Slide__date">{data[key].date}</p>
          <p className="Slide__materials">{data[key].materials}</p>
          <p className="Slide__dimensions">{data[key].dimensions}</p>
        </div>
      </SplideSlide>
    );
  });

  return (
    <div className="Sliders">
      <Splide
        className="Slider"
        ref={(slider) => (slider1.current = slider)}
      >
        {renderList1}
      </Splide>
      <Splide
        className="Slider"
        ref={(slider) => (slider2.current = slider)}
      >
        {renderList2}
      </Splide>
    </div>
  );
};

export default Carrusel;
