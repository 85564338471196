import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../../components/Grid/Grid";
import Header from "../../../components/Header/Header";
import Menu from "../../../components/Menu/Menu";
import "./Proyecto1.scss";

const Proyecto1 = () => {
  const { t } = useTranslation(["projects", "headers"]);
  const project1 = t("projects.todoEnElVacio", {
    ns: "projects",
    returnObjects: true,
  });
  const headerData = t("headers.proyecto1", {
    ns: "headers",
    returnObjects: true,
  });

  const header = {
    abbr: "PR1",
    surtitle: headerData.surtitle,
    title: headerData.title,
    subtitle: null,
  };

  return (
    <Suspense>
      <div className="Proyecto1">
        <Menu />
        <Header
          title={header.title}
          surtitle={header.surtitle}
          subtitle={header.subtitle}
          abbr={header.abbr}
        ></Header>
        <Grid list={project1} header={header} />
      </div>
    </Suspense>
  );
};

export default Proyecto1;
