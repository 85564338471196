import React from 'react'

import "./GridPr2.scss"

import expo1 from "./expo-1_850.jpg"
import expo2 from "./expo-2_850.jpg"
import expo3 from "./expo-3_850.jpg"
import expo4 from "./expo-4_850.jpg"
import expo5 from "./expo-5_850.jpg"
import expo6 from "./expo-6_850.jpg"

const GridPr2 = () => {
  return (
    <div className='GridPr2'>
        <img src={expo1} alt="img" />
        <img src={expo2} alt="img" />
        <img src={expo3} alt="img" />
        <img src={expo4} alt="img" />
        <img src={expo5} alt="img" />
        <img src={expo6} alt="img" />
    </div>
  )
}

export default GridPr2