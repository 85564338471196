import React from "react";
import { Breakpoint } from "react-socks";

import "./Header.scss";

const Header = (props) => {
  return (
    <div className="Header">
      <Breakpoint screenSm up>
        <div className="Header__abbr">
          <p>{props.abbr}</p>
        </div>
      </Breakpoint>
      <div className="Header__content">
        <p className="Header__sur h3">{props.surtitle}</p>
        <p className="Header__title h1">{props.title}</p>
        {props.subtitle ? (
          <p className="Header__sub h2">{props.subtitle}</p>
        ) : null}
        {props.cite ? <p className="Header__cite">{props.cite}</p> : null}
        {props.author ? (
          <p className="Header__citeAuthor">{props.author}</p>
        ) : null}
        {props.author2 ? (
          <p className="Header__author">{props.author2}</p>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
