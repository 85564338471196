import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import Menu from "../../../components/Menu/Menu";
import Header from "../../../components/Header/Header";
import TxtGrid from "../../../components/TxtGrid/TxtGrid";
import SliderInv from "../../../components/SliderInv/SliderInv";
import "./Conjuncion.scss";

const Conjuncion = () => {
  const { t } = useTranslation("investigaciones");
  const works = t("investigaciones.conjuncion.works", { returnObjects: true });
  const data = t("investigaciones.conjuncion", { returnObjects: true });

  return (
    <Suspense fallback="cargando..." >
      <div className="Conjuncion">
        <Menu />
        <Header
          abbr="CON"
          surtitle={data.header.surtitle}
          title={data.header.title}
        ></Header>
        <SliderInv slides={works} />
        <TxtGrid list={works} body={data.body} />
      </div>
    </Suspense>
  );
};

export default Conjuncion;
