import React from "react";
import { Breakpoint } from "react-socks";

import Card from "../Grid/Card/Card";
import "./TxtGrid.scss";

const TxtGrid = (props) => {


  return (
    <div className="TxtGrid">
      <div className="TxtGrid__body">
        <p className="TxtGrid__text">{props.body}</p>
      </div>
      <Breakpoint screenSm up>
        <div className="TxtGrid__grid">
          {Object.keys(props.list).map((key) => {
            return <Card data={props.list[key]} key={props.list[key].title} />;
          })}
        </div>
      </Breakpoint>
    </div>
  );
};

export default TxtGrid;
