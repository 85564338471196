import React from "react";

import "./TxtBio.scss"
import img from "./img-german-bio.jpg"

const TxtBio = (props) => {

  return (
    <div className="TxtBio">
      <div className="TxtBio__body">
        <p className="TxtBio__text">{props.body}</p>
      </div>
      <div className="TxtBio__img">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default TxtBio;
