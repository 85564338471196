import React, { useEffect, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";
import "./SliderInv.scss";

const SliderInv = (props) => {
  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    slider1.current.sync(slider2.current.splide);
  }, [slider1, slider2]);

  const renderList1 = Object.keys(props.slides).map((key) => {
    return (
      <SplideSlide key={props.slides[key].title}>
        <img src={props.slides[key].imageSm} alt="img" className="Slide__img" />
      </SplideSlide>
    );
  });
  const renderList2 = Object.keys(props.slides).map((key) => {
    return (
      <SplideSlide key={props.slides[key].title}>
        <p className="Slide__title">{props.slides[key].title}</p>
        <p className="Slide__date">{props.slides[key].date}</p>
        <p className="Slide__materials">{props.slides[key].materials}</p>
        <p className="Slide__dimensions">{props.slides[key].dimensions}</p>
      </SplideSlide>
    );
  });

  return (
    <div className="SliderInv">
      <Splide
        className="SliderC Slider"
        options={{
          arrows: false,
          pagination: false,
          height: 600,
          width: 600,
          breakpoints: {
            1024: {
              height: "auto",
              width: "auto",
            },
          },
        }}
        ref={(slider) => (slider1.current = slider)}
      >
        {renderList1}
      </Splide>
      <Splide
        className="SliderD Slider"
        options={{ arrows: true, pagination: false }}
        ref={(slider) => (slider2.current = slider)}
      >
        {renderList2}
      </Splide>
    </div>
  );
};

export default SliderInv;
