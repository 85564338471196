import React from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "./MenuMobile.scss";
import AccordionMenu from "../../AccordionMenu/AccordionMenu";
import Lang from "../../Lang/Lang";
import cross from "../../../assets/img/icon-white-cross.svg";

const MenuMobile = (props) => {
  return (
    <AnimatePresence>
        <motion.div
          key="modal"
          className="MenuMobile"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, top: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <header className="MenuMobile__header">
            <Link to="/" className="MenuMobile__title">
              <p>germán</p>
              <p>consetti</p>
            </Link>
            <button
              className="MenuMobile__btn"
              onClick={() => {
                props.setState(false);
              }}
            >
              <img src={cross} alt="Boton Cerrar Menu" />
            </button>
          </header>
          <AccordionMenu />
          <Lang />
        </motion.div>
    </AnimatePresence>
  );
};

export default MenuMobile;
