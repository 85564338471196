import React, { useEffect } from "react";
import "./HeaderMobile.scss";

const HeaderMobile = (props) => {
  useEffect(() => {
    const HeaderMobile = document.querySelector(".HeaderMobile");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 150) {
        HeaderMobile.classList.add("sticky");
      } else if (window.scrollY < 150) {
        HeaderMobile.classList.remove("sticky");
      }
    });
  });

  return (
    <div className="HeaderMobile">

      <header className="HeaderMobile__static">
        <div className="HeaderMobile__logo">
          <p>germán</p>
          <p>consetti</p>
        </div>
        <button
          className="HeaderMobile__menuBtn"
          onClick={() => {
            props.setState(true);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>

      <header className="HeaderMobile__sticky">
        <div className="HeaderMobile__sticky-logo">
          <span>C</span>
        </div>
        <div className="HeaderMobile__sticky-separator">
          <span>|</span>
        </div>
        <button
          className="HeaderMobile__menuBtn"
          onClick={() => {
            props.setState(true);
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </header>

    </div>
  );
};

export default HeaderMobile;
