import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";

import "./Banner.scss";

const Banner = () => {
  const options = {
    type: "loop",
    pagination: false,
    arrows: false,
    drag: false,
    autoplay: true,
    pauseOnHover: false,
    interval: 4000,
  };

  return (
    <Splide className="Banner" options={options} hasTrack={false}>
      <SplideTrack>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Escultura_2400/the-delphic-mystery_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670066508515"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Escultura_2400/the-inner-dimension_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670066508816"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Escultura_2400/la-disolucion-del-uno_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670066505833"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Escultura_2400/la-sibila_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670066506362"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Escultura_2400/skull_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670066507614"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
        <SplideSlide className="SlideItem">
          <img
            src="https://ik.imagekit.io/cpagkt1bq/Pintura_2400/auric-form_2400.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1670071187507"
            alt="img"
            className="Slide__img"
          />
        </SplideSlide>
      </SplideTrack>
    </Splide>
  );
};

export default Banner;
