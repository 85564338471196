import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import "./Card.scss"
import icon from "./icono-mas-hover.svg"

const Card = (props) => {

  const dispatch = useDispatch();

  const clickHandler = () => {
    dispatch({type: 'setid', setId: props.data.id})
  }

  return (
    <div>
      <Link to="/ficha" onClick={clickHandler}>
        <div className="Card">
          <div className="Card__img">
            <img src={props.data.imageSm} alt="" />
            <div className="Card__hover">
              <img src={icon} alt="" className="" />
            </div>
          </div>
          <div className="Card__bottom">
            <p className="Card__bottom--title">{props.data.title}</p>
            <p className="Card__bottom--material">{props.data.materials}</p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
