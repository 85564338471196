import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../components/Grid/Grid";
import Header from "../../components/Header/Header";
import Menu from "../../components/Menu/Menu";
import styles from "./Pinturas.module.scss";

const Pinturas = () => {
  const { t } = useTranslation(["artworks", "headers"]);
  const paintings = t("artworks.paintings", {
    ns: "artworks",
    returnObjects: true,
  });
  const headerData = t("headers.paintings", {
    ns: "headers",
    returnObjects: true,
  });

  const header = {
    abbr: "PNT",
    surtitle: headerData.surtitle,
    title: headerData.title,
    subtitle: null,
  };

  return (
      <div className={styles.Pintura}>
        <Menu />
        <Header
          title={header.title}
          surtitle={header.surtitle}
          subtitle={header.subtitle}
          abbr={header.abbr}
        ></Header>
        <Grid list={paintings} />
      </div>
  );
};

export default Pinturas;
