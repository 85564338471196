import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import "./Azul.scss";
import Menu from "../../../components/Menu/Menu";
import Header from "../../../components/Header/Header";
import CarruselCol from "../../../components/CarruselCol/CarruselCol";
import Txt from "../../../components/Txt/Txt";

const Azul = () => {
  const { t } = useTranslation("colaboraciones");
  const data = t("colaboraciones.azul", { returnObjects: true });

  return (
    <Suspense>
      <div className="Azul">
        <Menu />
        <Header
          title={data.title}
          surtitle={data.surtitle}
          subtitle={null}
          abbr="AZL"
        />
        <CarruselCol links={data.imgs} />
        <Txt body={data.body} />
      </div>
    </Suspense>
  );
};

export default Azul;
