import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../../components/Grid/Grid";
import GridPr2 from "../../../components/GridPr2/GridPr2";
import Header from "../../../components/Header/Header";
import Menu from "../../../components/Menu/Menu";
import ImgTxt from "../../../components/ImgTxt/ImgTxt";
import "./Proyecto2.scss";

const Proyecto2 = () => {
  const { t } = useTranslation(["projects", "headers"]);
  const gridItems = t("projects.elOrigenDeLaMateria.artworks", {
    ns: "projects",
    returnObjects: true,
  });
  const imgTxtData = t("projects.elOrigenDeLaMateria.imgTxt", {
    ns: "projects",
    returnObjects: true,
  });

  const headerData = t("headers.proyecto2", {
    ns: "headers",
    returnObjects: true,
  });

  const header = {
    abbr: "PR2",
    surtitle: headerData.surtitle,
    title: headerData.title,
    subtitle: null,
  };

  return (
    <Suspense>
      <div className="Proyecto2">
        <Menu />
        <Header
          title={header.title}
          surtitle={header.surtitle}
          subtitle={header.subtitle}
          abbr={header.abbr}
        ></Header>
        <ImgTxt data={imgTxtData} />
        <GridPr2 />
        <Grid list={gridItems} />
      </div>
    </Suspense>
  );
};

export default Proyecto2;
