import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./CarruselCol.scss";

const CarruselCol = (props) => {
  const linksArr = props.links;
  const slidesArr = linksArr.map((img) => {
    return (
      <SplideSlide className="Slide">
        <img src={img} alt="img" />
      </SplideSlide>
    );
  });

  return (
    <Splide
      className="CarruselCol"
      options={{
        perPage: 1,
        pagination: false,
        arrows: true,
        gap: 10,
        padding: 0,
        breakpoints: {
          1280: {
            padding: { left: 0, right: 40 },
            arrows: false,
          },
        },
      }}
    >
      {slidesArr}
    </Splide>
  );
};

export default CarruselCol;
