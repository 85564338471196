import React from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import "./AccordionMenu.scss"

import { useTranslation } from "react-i18next";

const AccordionMenu = () => {
  const { t } = useTranslation("menu");

  return (
    <Accordion defaultActiveKey="0" className="Nav">
      <Accordion.Item eventKey="0" className="Nav__item">
        <Accordion.Header className="Nav__header">
          <p className="menu__size">{t("menu.section.artwork.title")}</p>
        </Accordion.Header>
        <Accordion.Body className="Nav__links">
          <Link to="/escultura" className="menu__size">
            {t("menu.section.artwork.subsections.sculpture")}
          </Link>
          <Link to="/pintura" className="menu__size">
            {t("menu.section.artwork.subsections.painting")}
          </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1" className="Nav__item">
        <Accordion.Header className="Nav__header">
          <p className="menu__size">{t("menu.section.reserach.title")}</p>
        </Accordion.Header>
        <Accordion.Body className="Nav__links">
          <Link to="/investigaciones/conjuncion" className="menu__size">
            {t("menu.section.reserach.subsections.conjuncion")}
          </Link>
          <Link to="/investigaciones/transformacion" className="menu__size">
            {t("menu.section.reserach.subsections.transformacion")}
          </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="Nav__item">
        <Accordion.Header className="Nav__header">
          <p className="menu__size">{t("menu.section.projects.title")}</p>
        </Accordion.Header>
        <Accordion.Body className="Nav__links">
          <Link to="/proyectos/todo-en-el-vacio" className="menu__size">
            {t("menu.section.projects.subsections.first")}
          </Link>
          <Link to="/proyectos/el-origen-de-la-materia" className="menu__size">
            {t("menu.section.projects.subsections.second")}
          </Link>
          <Link to="/proyectos/una-metafora-es-una-matriz" className="menu__size">
            {t("menu.section.projects.subsections.third")}
          </Link>
          <Link to="/proyectos/detras-del-velo" className="menu__size">
            {t("menu.section.projects.subsections.fourth")}
          </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="Nav__item">
        <Accordion.Header className="Nav__header">
          <p className="menu__size">{t("menu.section.info.title")}</p>
        </Accordion.Header>
        <Accordion.Body className="Nav__links">
          <Link to="/info/biografia" className="menu__size">
            {t("menu.section.info.subsections.bio")}
          </Link>
          <Link to="info/taller" className="menu__size">
            {t("menu.section.info.subsections.taller")}
          </Link>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4" className="Nav__item">
        <Accordion.Header className="Nav__header">
          <p className="menu__size">{t("menu.section.collaborations.title")}</p>
        </Accordion.Header>
        <Accordion.Body className="Nav__links">
          <Link to="/colaboraciones/azul" className="menu__size">
            {t("menu.section.collaborations.subsections.first")}
          </Link>
          <Link to="/colaboraciones/rubi" className="menu__size">
            {t("menu.section.collaborations.subsections.second")}
          </Link>
          <Link to="/colaboraciones/tipel" className="menu__size">
            {t("menu.section.collaborations.subsections.third")}
          </Link>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionMenu;
