import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store from "./store/index"
import  { BreakpointProvider } from 'react-socks';
import "./i18n";
import "./index.scss";

import "./assets/fonts/Raleway-Thin.woff";
import "./assets/fonts/Raleway-Thin.woff2";
import "./assets/fonts/Raleway-Light.woff";
import "./assets/fonts/Raleway-Light.woff2";
import "./assets/fonts/Raleway-Regular.woff";
import "./assets/fonts/Raleway-Regular.woff2";
import "./assets/fonts/Raleway-Medium.woff";
import "./assets/fonts/Raleway-Medium.woff2";
import "./assets/fonts/Raleway-Bold.woff";
import "./assets/fonts/Raleway-Bold.woff2";
import "./assets/fonts/Raleway-Black.woff";
import "./assets/fonts/Raleway-Black.woff2";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BreakpointProvider>
      <Suspense>
        <App />
      </Suspense>
    </BreakpointProvider>
  </Provider>
);
