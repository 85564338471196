import { createStore } from "redux";

const idReducer = (state = { id: "E01" }, action) => {
  if (action.type === "setid") {
    return {
      id: action.setId,
    };
  }

  return state;
};

const store = createStore(idReducer);

export default store;
