import React from "react";

import "./Txt.scss";

const Txt = (props) => {

  return (
    <div className="Txt">
      <div className="Txt__body">
        <p className="Txt__text">{props.body}</p>
      </div>
    </div>
  );
};

export default Txt;