import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";
import "./Slider.scss";

const Slider = () => {
  const idState = useSelector((state) => state.id);
  const { t } = useTranslation("artworks");

  const type = idState.slice(0, 1);
  const num = idState.slice(1, 3);
  const idNum = num - 1;
  let data = {};

  console.log(idNum)

  if (type === "E") {
    data = t("artworks.sculptures", { returnObjects: true });
  } else if (type === "P") {
    data = t("artworks.paintings", { returnObjects: true });
  }

  const slider1 = useRef();
  const slider2 = useRef();

  useEffect(() => {
    slider1.current.sync(slider2.current.splide);
  }, [slider1, slider2]);

  const renderList1 = Object.keys(data).map((key) => {
    return (
      <SplideSlide key={data[key].title} >
        <img src={data[key].imageLg} alt="img" className="Slide__img" />
      </SplideSlide>
    );
  });
  const renderList2 = Object.keys(data).map((key) => {
    return (
      <SplideSlide key={data[key].title}>
        <p className="Slide__title">{data[key].title}</p>
        <p className="Slide__date">{data[key].date}</p>
        <p className="Slide__materials">{data[key].materials}</p>
        <p className="Slide__dimensions">{data[key].dimensions}</p>
      </SplideSlide>
    );
  });

  return (
    <div className="Sliders">
      <Splide
        className="SliderA Slider"
        options={{ start: idNum, arrows: false, pagination: false}}
        ref={(slider) => (slider1.current = slider)}
      >
        {renderList1}
      </Splide>
      <Splide
        className="SliderB Slider"
        options={{ start: idNum, pagination: false, arrows: true }}
        ref={(slider) => (slider2.current = slider)}
      >
        {renderList2}
      </Splide>
    </div>
  );
};

export default Slider;
