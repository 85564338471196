import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Lang.scss";

const Lang = () => {
  const { i18n } = useTranslation("menu");

  useEffect(() => {

    const spaBtn = document.querySelector(".spaBtn");
    const engBtn = document.querySelector(".engBtn");

    if (i18n.language === "es") {
      spaBtn.classList.add("active");
      engBtn.classList.remove("active");
    } else if (i18n.language === "en") {
      engBtn.classList.add("active");
      spaBtn.classList.remove("active");
    }
  });

  return (
    <div className="Lang">
      <button
        className="spaBtn langBtn active"
        onClick={(lang) => {
          i18n.changeLanguage("es");
        }}
      >
        <p className="h3">es</p>
      </button>
      <p className="h3">|</p>
      <button
        className="engBtn langBtn"
        onClick={(lang) => i18n.changeLanguage("en")}
      >
        <p className="h3">en</p>
      </button>
    </div>
  );
};

export default Lang;
