import React from "react";

import Menu from "../../components/Menu/Menu";
import Banner from "../../components/Banner/Banner";

import "./Home.scss";

const Home = () => {
  return (
    <div className="Home">
      <Menu />
      <Banner />
    </div>
  );
};

export default Home;
