import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "../../../components/Grid/Grid";
import Header from "../../../components/Header/Header";
import Menu from "../../../components/Menu/Menu";
import "./Proyecto4.scss";

const Proyecto4 = () => {

  const { t } = useTranslation(["projects", "headers"]);
  const project4 = t("projects.detrasDelVelo", { ns: "projects", returnObjects: true });
  const headerData = t("headers.proyecto4", { ns: "headers", returnObjects: true });

  const header = {
    abbr: "PR4",
    surtitle: headerData.surtitle,
    title: headerData.title,
    subtitle: null,
  };

  return (
    <div className="Proyecto4">
      <Menu />
      <Header
        title={header.title}
        surtitle={header.surtitle}
        subtitle={header.subtitle}
        abbr={header.abbr}
      ></Header>
      <Grid list={project4} header={header} />
    </div>
  );
};

export default Proyecto4;
