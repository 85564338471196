import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { setDefaultBreakpoints } from 'react-socks';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";

import Home from "./pages/Home/Home";
import Esculturas from "./pages/Esculturas/Esculturas";
import Pinturas from "./pages/Pinturas/Pinturas";
import Ficha from "./pages/Ficha/Ficha";
import Carrusel from "./pages/Carrusel/Carrusel";
import Bio from "./pages/Bio/Bio";
import Conjuncion from "./pages/Investigaciones/Conjuncion/Conjuncion";
import Transformacion from "./pages/Investigaciones/Transformacion/Transformacion";
import Proyecto1 from "./pages/Proyectos/Proyecto1/Proyecto1";
import Proyecto2 from "./pages/Proyectos/Proyecto2/Proyecto2";
import Proyecto3 from "./pages/Proyectos/Proyecto3/Proyecto3";
import Proyecto4 from "./pages/Proyectos/Proyecto4/Proyecto4";
import Azul from "./pages/Colaboraciones/Azul/Azul";
import Rubi from "./pages/Colaboraciones/Rubi/Rubi";
import Tipel from "./pages/Colaboraciones/Tipel/Tipel";

function App() {

  setDefaultBreakpoints([
    { mobile: 0 },
    { mobileLg: 576 },
    { tablet: 768 },
    { tabletH: 1024 },
    { screenSm: 1280 },
    { screenMd: 1440 },
    { screenLg: 1600 },
    { screenXl: 1920 }
  ]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/escultura" exact element={<Esculturas/>} />
          <Route path="/pintura" exact element={<Pinturas/>} />
          <Route path="/ficha" exact element={<Ficha/>} />
          <Route path="/carrusel" exact element={<Carrusel/>} />
          <Route path="/info/biografia" exact element={<Bio/>} />
          <Route path="/investigaciones/conjuncion" exact element={<Conjuncion/>} />
          <Route path="/investigaciones/transformacion" exact element={<Transformacion/>} />
          <Route path="/proyectos/todo-en-el-vacio" exact element={<Proyecto1/>} />
          <Route path="/proyectos/el-origen-de-la-materia" exact element={<Proyecto2/>} />
          <Route path="/proyectos/una-metafora-es-una-matriz" exact element={<Proyecto3/>} />
          <Route path="/proyectos/detras-del-velo" exact element={<Proyecto4/>} />
          <Route path="/colaboraciones/azul" exact element={<Azul/>} />
          <Route path="/colaboraciones/rubi" exact element={<Rubi/>} />
          <Route path="/colaboraciones/tipel" exact element={<Tipel/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
